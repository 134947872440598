import React from "react"
// import SEO from "../components/seo.component"
import Layout from "../layouts"

const NotFoundPage = () => (
  <Layout siteTitle={`404: Not found page`}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
